
$(document).ready(function () {
    $("#digital_asset_modal").css('opacity',0.6)
    let value = "0.00";

    $('*[id*=dashboard-summary-user]').each(function() {
        console.log('value before calculation ==', value)
        var subscriptionValue =$(this).find("#subscription-amount").html();
        var valueWithNoCurrency = subscriptionValue.split("RM").slice(-1)[0]

        value = (parseFloat(valueWithNoCurrency)+ parseFloat(value)).toFixed(2)

    });

    var assetLength= $('*[id*=dashboard-summary-user]').length;

    $("#dashboard-asset-length").html(assetLength)

    $("#dashboard-asset-value").html("RM ".concat(value))

})
