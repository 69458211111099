$(document).ready(function () {

    var isValidPhoneNumber = function() {
        var value = $(this).val();
        var is_numbers_only = (value.match(/^[0-9+]+[0-9+]*$/))
        var getId = $(this).attr("id")
        var getMessageId = `${getId}-message`

        if (is_numbers_only) {
            $(`#${getMessageId}`).text("")
        } else {
            $(`#${getMessageId}`).text("Please enter valid input").css("color","red")
            $("#update-executor").attr("disabled", true);
        }
    }

    var isValidIcNumber =  function() {
        var value = $(this).val();
        var is_numbers_only = (value.match(/^[0-9]+[0-9]*$/)) && (value.length === 12)

        if (is_numbers_only) {
            $("#identification-card-message").text("")
        } else {
            $("#identification-card-message").text("IC 12 digits with no '-' ").css("color","red")
            $("#update-executor").attr("disabled", true);
        }
    }


    var isValidated = function() {
        var isIcError = $("#identification-card-message").text().length > 0
        var isMobileError = $("#executor-mobile-message").text().length > 0
        var houseNumberError = $("#executor-phone-message").text().length > 0

        var isError = isIcError || isMobileError || houseNumberError

        if (isError) {
            $("#update-executor").attr("disabled", true);
        } else {
            $("#update-executor").attr("disabled", false);
        }
    }

    $("#identification-card")
        .keyup(isValidIcNumber)
        .focusout(isValidated)

    $("#executor-mobile")
        .keyup(isValidPhoneNumber)
        .focusout(isValidated)

    $("#executor-phone")
        .keyup(isValidPhoneNumber)
        .focusout(isValidated)
})
