$(document).ready(function () {
    console.log('Test Jquery working ')

    $('#client_search').on('click', function(e) {
        let firstName = document.getElementById("digital-wasiat-first-name").value
        let identificationCard = document.getElementById('digital-wasiat-identification-card').value

        const searchParams = firstName? firstName : identificationCard
        const isParamsFilled = firstName || identificationCard
        if (isParamsFilled) {
            $.ajax({
                url:`/people/client/${searchParams}/search_client`,
                // data: $form.serialize(),
                dataType: 'html',
                type: "GET",
                context: this,
                success: function (data) {
                    $('#client-search-table').html($(data).find('div[id$="client-info-table"]').html())
                    $('#client-search-table').show()
                    $('#client-info').css('background-color', '#F0F0F0');
                    $('#client-address').hide()
                },
                error: function (data) {
                    console.log("error message = ",data.responseText)
                }
            });
        } else {
            $('#client-search-table').hide()
        }

    })
})
