const Chart = require("chart.js/auto")

document.addEventListener("turbolinks:load", () => {

    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, args, options) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options.color || 'rgba(233,224,212,0.2)';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    // PMR chart in dashboard

    const pmr_chart = document.getElementById('pmr_chart');

    if (!!pmr_chart) {
        const pmr_chart_data_title = JSON.parse(pmr_chart.dataset.pageTitle)
        const pmr_chart_data_content = JSON.parse(pmr_chart.dataset.pageContent)
        const pmr_chart_chart_title = pmr_chart.dataset.chartTitle
        console.log('pmr_chart_data_content', pmr_chart_data_title)
        let chartColor = []

        pmr_chart_data_title.forEach((chartTitle)=> {
            console.log('chartTitle ==', chartTitle)
            if (chartTitle === "Sedang Proses") {
                chartColor.push('#FFFF9F')
            } else if (chartTitle === "Lulus") {
                chartColor.push('#90EE90')
            } else if (chartTitle ==="Ditolak") {
                chartColor.push('#DC143C')
            } else {
                chartColor.push('#40E0D0')
            }
        })
        console.log('chartColor ==', chartColor)
        new Chart.Chart(pmr_chart, {
            type: 'doughnut',
            data: {
                labels: pmr_chart_data_title,
                datasets: [{
                    label: 'RM',
                    data: pmr_chart_data_content,
                    backgroundColor: chartColor
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: pmr_chart_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }


    const kebajikan = document.getElementById('kebajikan');
    console.log("kebajikan ==", kebajikan)
    if (!!kebajikan) {
        const kebajikan_data_title = JSON.parse(kebajikan.dataset.pageTitle)
        const kebajikan_data_content = JSON.parse(kebajikan.dataset.pageContent)
        const kebajikan_chart_title = kebajikan.dataset.chartTitle

        // Kebajikan Chart in dashboard
        new Chart.Chart(kebajikan, {
            type: 'doughnut',
            data: {
                labels: kebajikan_data_title,
                datasets: [{
                    label: 'RM',
                    data: kebajikan_data_content,
                    backgroundColor: [
                        'rgba(255,99,132)',
                        'rgba(54,162,235)',
                        '#EE82EE',
                        '#FFADAD',
                        '#228B22',
                        '#A0C3D2'
                    ]
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: kebajikan_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }

    const monthly_expenses = document.getElementById('monthly_expenses');

    if (!!monthly_expenses) {
        const monthly_expenses_data_title = JSON.parse(monthly_expenses.dataset.pageTitle)
        const monthly_expenses_data_content = JSON.parse(monthly_expenses.dataset.pageContent)
        const monthly_expenses_chart_title = monthly_expenses.dataset.chartTitle

        // Monthly expenses Chart in dashboard
        new Chart.Chart(monthly_expenses, {
            type: 'bar',
            data: {
                labels: monthly_expenses_data_title,
                datasets: [{
                    label: 'RM',
                    data: monthly_expenses_data_content
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: monthly_expenses_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }



    const kebajikan_dun = document.getElementById('kebajikan_dun');

    if (!!kebajikan_dun) {
        const kebajikan_dun_data_title = JSON.parse(kebajikan_dun.dataset.pageTitle);
        const kebajikan_dun_data_content = JSON.parse(kebajikan_dun.dataset.pageContent);
        const kebajikan_dun_chart_title = kebajikan_dun.dataset.chartTitle;

        // Kebajikan Chart in dashboard
        new Chart.Chart(kebajikan_dun, {
            type: 'doughnut',
            data: {
                labels: kebajikan_dun_data_title,
                datasets: [{
                    label: 'RM',
                    data: kebajikan_dun_data_content,
                    backgroundColor: [
                        '#FDAB9F',
                        '#9E4244',
                        '#800080',
                        '#CBC3A3',
                        '#603FEF',
                        '#f37a48',
                        '#979797'
                    ]
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: kebajikan_dun_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }

    const kebajikan_category = document.getElementById('kebajikan_category');

    if (!!kebajikan_category) {
        const kebajikan_category_data_title = JSON.parse(kebajikan_category.dataset.pageTitle);
        const kebajikan_category_data_content = JSON.parse(kebajikan_category.dataset.pageContent);
        const kebajikan_category_chart_title = kebajikan_category.dataset.chartTitle;

        // Kebajikan Chart in dashboard
        new Chart.Chart(kebajikan_category, {
            type: 'doughnut',
            data: {
                labels: kebajikan_category_data_title,
                datasets: [{
                    label: 'RM',
                    data: kebajikan_category_data_content,
                    backgroundColor: [
                        '#F28500',
                        '#3CAEA3',
                        '#20639B',
                        '#173F5F'
                    ]
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: kebajikan_category_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }

    const kebajikan_monthly_expenses = document.getElementById('kebajikan_monthly_expenses');

    if (!!kebajikan_monthly_expenses) {
        const monthly_expenses_data_title = JSON.parse(kebajikan_monthly_expenses.dataset.pageTitle)
        const monthly_expenses_data_content = JSON.parse(kebajikan_monthly_expenses.dataset.pageContent)
        const monthly_expenses_chart_title = kebajikan_monthly_expenses.dataset.chartTitle

        // Monthly expenses Chart in dashboard
        new Chart.Chart(kebajikan_monthly_expenses, {
            type: 'bar',
            data: {
                labels: monthly_expenses_data_title,
                datasets: [{
                    label: 'RM',
                    data: monthly_expenses_data_content
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: monthly_expenses_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }
    /////////////

    const mesra_dun = document.getElementById('mesra_dun');

    if (!!mesra_dun) {
        const mesra_dun_data_title = JSON.parse(mesra_dun.dataset.pageTitle);
        const mesra_dun_data_content = JSON.parse(mesra_dun.dataset.pageContent);
        const mesra_dun_dun_chart_title = mesra_dun.dataset.chartTitle;

        // Kebajikan Chart in dashboard
        new Chart.Chart(mesra_dun, {
            type: 'doughnut',
            data: {
                labels: mesra_dun_data_title,
                datasets: [{
                    label: 'RM',
                    data: mesra_dun_data_content,
                    backgroundColor: [
                        '#FDAB9F',
                        '#9E4244',
                        '#800080',
                        '#CBC3A3',
                        '#603FEF',
                        '#f37a48',
                        '#979797'
                    ]
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: mesra_dun_dun_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }

    const mesra_category = document.getElementById('mesra_category');

    if (!!mesra_category) {
        const mesra_category_data_title = JSON.parse(mesra_category.dataset.pageTitle);
        const mesra_category_data_content = JSON.parse(mesra_category.dataset.pageContent);
        const mesra_category_chart_title = mesra_category.dataset.chartTitle;

        // Mesra Chart in dashboard
        new Chart.Chart(mesra_category, {
            type: 'doughnut',
            data: {
                labels: mesra_category_data_title ,
                datasets: [{
                    label: 'RM',
                    data: mesra_category_data_content,
                    backgroundColor: [
                        '#F28500',
                        '#3CAEA3',
                        '#20639B',
                        '#173F5F'
                    ]
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: mesra_category_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }

    const mesra_monthly_expenses = document.getElementById('mesra_monthly_expenses');

    if (!!mesra_monthly_expenses) {
        const mesra_expenses_data_title = JSON.parse(mesra_monthly_expenses.dataset.pageTitle)
        const mesra_data_content = JSON.parse(mesra_monthly_expenses.dataset.pageContent)
        const mesra_expenses_chart_title = mesra_monthly_expenses.dataset.chartTitle

        // Monthly expenses Chart in dashboard
        new Chart.Chart(mesra_monthly_expenses, {
            type: 'bar',
            data: {
                labels: mesra_expenses_data_title,
                datasets: [{
                    label: 'RM',
                    data: mesra_data_content
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: mesra_expenses_chart_title,
                        position: 'bottom'
                    },
                    customCanvasBackgroundColor: {
                        color:'white',
                    }
                }

            },
            plugins: [plugin]
        });
    }
})
